/* 
==========
Container
==========
 */
.container {
  max-width: 960px;
  margin: auto;
}

.container-fluid {
  max-width: 100%;
  margin: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* 
==========
Flex
==========
 */

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex.row {
  flex-direction: row;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.space-between {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.flex.space-between.sm {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.flex.space-between.md {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .flex.space-between.sm {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .flex.space-between.md {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .flex.space-between {
    flex-direction: column;
  }
}
@media (max-width: 990px) {
  .flex.row {
    flex-direction: column;
  }
}

/* 
=============================
All Sections Under Main Intro
=============================
 */

.info-section {
  padding: 70px 0 70px 0;
  color: white;
}

.section-title-wrapper {
  margin: 0 0 70px 0;
}

.line {
  height: 4px;
  width: 70px;
  background-color: white;
  margin: 15px 0 0 0;
}

/* @media (min-width: 600px) {
  section {
    padding: 130px 0 130px 0;
  }
} */

/* 
==========
Intro
==========
 */

.intro-bg {
  background: radial-gradient(ellipse at bottom, #1b2836 0%, #16171f 100%);
  height: 100%;
  color: white;
}

.darkRectangle {
  width: 60%;
  margin: 0 auto;
  /* background-color: rgb(54, 54, 54); */
  padding: 50px;
  background-color: #1d2a36;
}

@media (max-width: 300px) {
  .darkRectangle {
    padding: 0;
  }
  .title-text span {
    font-size: 20px;
  }
  .button a {
    font-size: 12px;
  }
}

/* @media (max-width: 400px) {
  .darkRectangle {
    width: 10%;
    max-width: 200px;
  }
  .darkRectangle .title-text {
    font-size: 20px;
  }
} */

.title-text {
  font-size: 42px;
  text-align: center;
  margin-bottom: 30px;
}

.button {
  font-size: 28px;
  border: 2px solid white;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}

.button:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #2f4255;
}

.button a:link {
  color: white;
}

.button a:visited {
  color: white;
}

/* 
==========
About Me
==========
 */

.about-me {
  background-color: #14202e;
  height: 100%;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .skills-grid {
    padding: 0 20px;
  }
}

.skill {
  background-color: #193652;
  border: solid 1px white;
  padding: 2% 0;
  margin-bottom: 2%;
  text-align: center;
  flex: 0 32%;
}

.row-list-item {
  padding: 20px 0;
}

.circle {
  height: 100px;
  width: 100px;
  border: 1px solid white;
  border-radius: 50px;
  background-color: #193652;
}

/* 
==========
My Work
==========
 */

.my-work {
  background-color: #1c2f41;
  height: 100%;
}

.proj-img {
  width: 100%;
  height: auto;
  max-width: 800px;
}

@media (max-width: 430px) {
  .proj-img {
    width: 75%;
  }
}

.arrow-up {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.arrow-down {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}

.project {
  margin: 30px 30px;
}

.proj-button {
  font-size: 28px;
  border: 2px solid white;
  display: inline-block;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
  padding: 5px 15px;
}

.proj-button:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #2f4255;
}

.proj-links-row > * {
  margin: 20px 20px;
}

.button-proj-link {
  font-size: 16px;
  border: 2px solid white;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
  background-color: #1c2f41;
  color: white;
}

.button-proj-link:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #2f4255;
}

.button-proj-link a:link {
  color: white;
}

.button-proj-link a:visited {
  color: white;
}

.button-proj-link:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-proj-link a {
  text-decoration: none;
}

.flex.links-row {
  flex-direction: row;
}

@media (max-width: 400px) {
  .flex.links-row {
    flex-direction: column;
  }
}

/* .proj-img img {
  transition: 0.5 all;
}

.proj-img:hover {
  filter: brightness(50%);
}

.img-button {
  display: none;
  position: absolute;
}

img:hover + .img-button,
.img-button:hover {
  display: inline-block;
  padding: 200px 0 0 0;
} */

/* 
==========
Contact Me
==========
 */

.contact-me {
  background-color: #14202e;
  height: 100%;
}

#contact-form {
  width: 100%;
  max-width: 400px;
  /* margin-bottom: 30px; */
}

input,
textarea {
  color: white;
}

#contact-form input,
textarea {
  /* width: 100%;
  max-width: 300px; */
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 4px;
  margin-top: -4px;
}

@media (max-width: 480px) {
  #contact-form input,
  textarea {
    width: 75%;
    max-width: 300px;
  }
}

.valid {
  color: green;
}

.error {
  color: red;
}

#contact-form input,
textarea {
  background-color: #1c2f41;
  outline: none;
  border: 1px solid #487aa8;
}

input::placeholder,
textarea::placeholder {
  color: white;
  opacity: 0.8;
}

.submit-btn {
  font-size: 16px;
  border: 2px solid white;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
  background-color: #1c2f41;
  color: white;
}

.submit-btn:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #2f4255;
}

.submit-btn a:link {
  color: white;
}

.submit-btn a:visited {
  color: white;
}

/* .form-control {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
} */

/* 
==========
Navbar
==========
 */

.navbar {
  display: flex;
  justify-content: center;
  /* background-color: rgb(34, 34, 34); */
  background-color: #1c2f41;
  position: sticky;
  top: 0;
  color: white;
  border-bottom: 2px solid white;
  z-index: 1;
}

.brand-title {
  /* font-size: 1.5rem; */
  margin: 0.5rem;
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  /* background-color: rgb(34, 34, 34); */
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
  display: block;
}

.navbar-links li:hover {
  background-color: #2f4255;
}

.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 400px) {
  .toggle-button {
    display: flex;
  }
  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }
  .navbar-links li {
    text-align: center;
  }
  .navbar-links li a {
    padding: 0.5rem 1rem;
  }
  .navbar-links.active {
    display: flex;
  }
}

/* 
==========
Footer
==========
 */

.footer {
  display: flex;
  justify-content: center;
  /* background-color: rgb(34, 34, 34); */
  background-color: #1c2f41;
  position: sticky;
  top: 0;
  color: white;
  z-index: 1;
}

.footer-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  /* background-color: rgb(34, 34, 34); */
}

.footer-links li {
  list-style: none;
}

.footer-links li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
  display: block;
}

.footer-links li:hover {
  background-color: #2f4255;
}
